.personal-page {
  font-family: Arial, sans-serif;
  color: #fff;
  background-color: #111111;
  padding: 20px;
}

.about-me-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: large;
  padding-left: 0lvb;
  padding-right: 0lvb;
  color: #fff;
}

.textbox {
  color: #989898;
}



.about {
  color: #fff;
}

.foot{
  background-color: #05101c;  
  color: #ffffff;
}

.left-box {
  flex: 0.7; /* Adjust this value to set the width of the "About Me" section */
  background-color: #091d33;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.left-box:hover {
  background-color: #0b243e;
  transition: 0.5s;
}

.extra-box {
  flex: 1; /* Adjust this value to set the width of the "About Me" section */
  background-color: #091d33;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.extra-box:hover {
  background-color: #0b243e;
  transition: 0.5s;
}


.right-box {
  flex: 0.28; /* Adjust this value to set the width of the profile picture section */
  text-align: center;
  color: #fff;
  justify-content: center;
}

.right-box img {
  width: 28vh;
  border-radius: 10px;
}

.additional-boxes {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* gap: 20px; */
  margin-top: 20px;
  padding-left: 0lvb;;
  padding-right: 0lvb;
}

.info-box {
  flex: 0.5; /* Adjust this value to set the width of each additional box */
  background-color: #091d33;
  color: #fff;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.info-box:hover {
  background-color: #0b243e;
  transition: 0.5s;
}

@media (min-width: 768px) {
  .additional-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .info-box {
    flex: 0.5; /* Adjust this value to set the width of each additional box */
  }

  .about-me-section {
    padding-left: 14lvb;
    padding-right: 14lvb;
  }

  .additional-boxes {
    padding-left: 14lvb;
    padding-right: 14lvb;
  }

}

/* Styles for mobile screens (small screens) */
@media (max-width: 767px) {
  .additional-boxes {
    flex-direction: column;
  }

  .info-box {
    flex: 1; /* Take full width of the screen */
  }

  .right-box img {
    width: 20vh; /* Adjust the width as needed for larger mobile images */
  }
}



.experience-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.experience-item {
  margin-bottom: 20px;
  border-left: 2px solid #007bff;
  padding-left: 10px;
}

.experience-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.experience-date {
  font-size: 0.9rem;
  color: #777;
}

.experience-description {
  margin-top: 10px;
}