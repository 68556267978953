/* Dropdown Button */
.dropbtn {
    background-color: #04AA6D;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: absolute;
    justify-content: center;
    box-sizing: border-box;
    
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    opacity: 0%;
    transition: 1s;
    width: -webkit-fill-available;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: Poppins;
    position: absolute;
    background-color: #92c1d3;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd; transition: 0.5s;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    transform: scaleX(1);
    transform: translateY(2px);
    opacity: 100%;

   }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }

  .button4-primary {
    position: relative;
    width: -webkit-fill-available;
    height: 30px;
    background: rgb(75, 75, 75);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    letter-spacing: 0.05em;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 5px;
  }
  
  .button4-primary::before{
    content: '';
    border-radius: 5px;
  
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #359ad8;
    transform: scaleX(0);
    transition: transform 0.5s ease-in-out;
    transform-origin: right;
    opacity: 0.2;
  }
  .button4-primary:hover::before{
    transform: scaleX(1);
    transform-origin: left;
    opacity: 0.2;  
  }