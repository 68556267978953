.button-good {
    color: black;
}

.react-pdf__Page__textContent span {
    display: none;
}

.react-pdf__Page__canvas {
    box-shadow: 10px;
    outline: black 3px solid;
}

.react-pdf__Page{ display: flex;}

.entire {
    background-color: #111;
}

.h1edit {
    color: #fff;
}

.h2edit {
    text-align: center;
    color: #fff;
    font-size: 1rem;
    padding-left: 5lvb;
    padding-right: 5lvb;
}

h1 {
    /* background-color: green; */
    margin-top: 0.5em;
    font-size: 30rem;
    font-family: Poppins;
    color: rgb(0, 0, 0);
    line-height: 100%;
    justify-content: center;
    text-align: center;
  }