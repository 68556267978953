
.page-holder {
  min-height: 100vh;
}

body {
  background: #000000;
  /* background: -webkit-linear-gradient(to right, #E8CBC0, #0d0f14); */
  /*background: linear-gradient(to right, #526de6, #526de6);*/
  min-height: 100vh;
}

.bg-cover {
  background-size: cover !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tapestry&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap");
:root {
  --sub_title_color: rgb(242, 242, 242);
  --yellow_title: #00cba4;
  --sp_service_title: #007eae;
  --sp_service_content: #32568b;
  --audit_button_border: #de78b3;
  --linear-header: linear-gradient(to right, #9c6cb0, rgba(50, 86, 139, 0.9));
  --linear_title: linear-gradient(to right, #9c6cb0, #32568b);
  --linear-article: linear-gradient(to right, #9c6cb0, #32568b);
  --reverse_linear_title: linear-gradient(to left, #32568b, #9c6cb0);
  --articles-card: #32568b;
  --footer_background: #2b2e41;
}

.btn{
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  background: #1E90FF;
  width: 160px;
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}

.btn:hover, .btn:focus, btn:active{
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*? Components */
/*? Header */
.fixed {
  position: fixed;
  top: 0px;
}

.header {
  background: var(--linear-header);
  width: 100%;
  height: 80px;
  position: absolute;
  z-index: 10;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: start;
}

.logo {
  width: 25%;
  margin-left: 1em;
  margin-top: 0.02em;
  padding-left: 0.5em;
  font-weight: 500;
  color: white;
  font-size: 3rem;
  font-family: Poppins;
}

.logo span {
  font-weight: 400;
  font-size: 2.5rem;
}

.nav-bar ul {
  margin-top: 2.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.nav-bar ul li {
  padding: 1em 2em 1em 2em;
  font-size: 0.75rem;
  font-family: "Work Sans";
  font-weight: 500;
  color: rgb(225, 225, 225);
  border-bottom: 3px solid transparent;
  transition: 0.3s ease-in-out;
}

.nav-bar ul li:hover {
  color: white;
  cursor: pointer;
}

.nav-bar-focus {
  color: white;
  cursor: pointer;
  border-bottom: 3px solid white;
}

/*? Footer  */
.footer-container {
  position: static;
  bottom: 0px;
  width: 100%;
  height: 300px;
  background-color: var(--footer_background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: Poppins;
}

.footer-title {
  color: white;
  font-size: 4rem;
}

.footer-title span {
  color: rgb(242, 242, 242);
  font-size: 3rem;
}

.footer-desc {
  color: gray;
  font-size: 0.9rem;
}

.footer-contact div {
  font-size: 0.7rem;
  color: var(--sub_title_color);
  margin-top: 10px;
}

.footer-divider {
  margin-top: 20px;
  width: 60%;
  height: 1px;
  background-color: gray;
}

/*? Card Component */
.card-container {
  width: 300px;
  height: 350px;
  background-color: white;
  box-shadow: 0px 0px 5px rgb(89, 89, 89);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.3s ease-in-out;
}

.card-container:hover {
  transform: scale3d(1.05, 1.05, 1.05);
}

.card-title {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Poppins;
}

.card-title-info {
  font-weight: 400;
  font-family: Poppins;
  text-align: center;
}

.card-description {
  font-size: 0.8rem;
  color: rgb(49, 49, 49);
  font-weight: 200;
  font-family: Poppins;
  width: 80%;
  text-align: center;
  height: 150px;
}

.card-button {
  /* background: var(--linear_title); */
  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: lighter;
  letter-spacing: 1px;
  padding: 6px 8px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  animation-name: background;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  transition: 0.5s ease-in-out;
}

@keyframes background {
  0% {
    background: #32568b;
  }
  50% {
    background: #9c6cb0;
  }
  100% {
    background: #32568b;
  }
}

@keyframes background-article {
  0% {
    background: #32568b;
  }
  50% {
    background: #3492fa;
  }
  100% {
    background: #32568b;
  }
}

/* .card-button:hover {
} */

/*? Article  */
.articles-swiper {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.article-container {
  width: 320px;
  height: 300px;
  background-color: var(--articles-card);
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(89, 89, 89);
  margin-bottom: 180px;
  transition: 0.5s ease-in-out;
}

.article-container:hover {
  transform: translateY(-15px);
}

.article-title {
  padding: 35px 40px;
  height: 225px;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 300;
  color: white;
}

.article-desc {
  text-align: center;
  font-family: Poppins;
  color: #aecaff;
  margin-top: -20px;
  font-size: 0.8rem;
}

.article-button {
  display: inline;
  padding: 10px 15px;
  background-color: #2c5186;
  color: white;
  font-size: 0.8rem;
  margin-top: 10px;
  font-family: Poppins;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}

.article-button:hover {
  background-color: #5676ae;
}

/*? Pages */

/*? Home Page */

.section-1 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom right, #111111, #282828);
  /* background-color: #111111; */
}


.imagetest {
  transition: 1s;
}

.imagetest3 {
  transition: 0.8s;
}

.imagetest4 {
  transition: 0.8s;
}
.imagetest4:hover{
  filter: brightness(0.4);
}

.imagetest2 {
  filter: brightness(0) invert(1);
  transition: 0.8s;
}

.imagetest2:hover{
  filter: brightness(0) invert(0.5);
  text-shadow: rgb(14, 13, 13) 1px 0 10px;
}

.imagetest3:hover{
  filter: brightness(0) invert(0.5);
  text-shadow: rgb(14, 13, 13) 1px 0 10px;
}

.imagetest:hover {
  transform-origin: top left;
  transform: scale(1.1);
  text-shadow: rgb(14, 13, 13) 1px 0 10px;

}

.background {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 120vh;
  object-fit: cover;
  filter: blur(0px);
  -webkit-filter: blur(0px);
  transition: 0.5s;
  background-color: #359ad8;;
  filter: blur(2.5px);

}
.section-1:hover .background{
  filter: blur(4px);
  -webkit-filter: blur(4px);
  opacity: 0.9;
}

.title {
  /* background-color: green; */
  margin-top: 0.5em;
  font-size: 5rem;
  font-family: Poppins;
  color: white;
  line-height: 100%;
  transition: 1s;
  justify-content: center;
  text-align: center;
  text-shadow: rgb(14, 13, 13) 1px 0 10px;

}

/* .title:hover {
  text-shadow: rgb(14, 13, 13) 1px 0 10px;
} */

.title span {
  font-size: 4rem;
  color: rgb(233, 233, 233);
}

.sub-title {
  font-size: 2rem;
  font-family: Poppins;
  color: var(--sub_title_color);
  margin-top: 0.5em;
  margin-bottom: 1em;
  word-spacing: 7px;
  letter-spacing: 2px;
  text-align: center;
  text-shadow: rgb(14, 13, 13) 1px 0 10px;
}

.buttons {
  margin-top: 2em;
  width: 23%;
  display:contents;
  justify-content: space-between;
  align-items: center;
}

.buttons div {
  width: 8rem;
  padding: 8px 16px;
  font-size: 0.8rem;
  letter-spacing: 2px;
  font-family: Poppins;
  font-weight: 400;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.button-primary {
  position: relative;
  height: 30px;
  /* background: #333; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  letter-spacing: 0.05em;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 5px;
}



.button-primary::before{
  content: '';
  border-radius: 5px;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #359ad8;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  transform-origin: right;
  opacity: 0.2;
}
.button-primary:hover::before{
  transform: scaleX(1);
  transform-origin: left;
  opacity: 0.2;  
}

.button-secondary {
  border: 1px solid white;
  color: white;
  position:relative;
  box-shadow: 0 0 0 0.05rem rgba(81, 21, 202, 0.1);
  align-items: center;

}

.button-three {
  border: 1px solid white;
  color: white;
  background-color: #007eae;
  box-shadow: 0 0 0 0.05rem rgba(81, 21, 202, 0.1);
  justify-content: center;
  position: relative;
  align-items: center;

}
.button-three:hover,
.button-secondary:hover{
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  color: #fff;
}



.section-2 {
  background-color: white;
  width: 100%;
  height: 140vh;
}

.video-background {
  width: 100%;
  /* height: 100vh; */
}

.sp-title {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  padding-top: 2.5em;
  color: var(--yellow_title);
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-title-content {
  padding-left: 1rem;
}

.sp-divider {
  width: 10px;
  height: 80px;
  background-color: var(--yellow_title);
}



.sp-content {
  display: flex;
  align-items: center;
  padding-top: 2em;
}

.sp-content > div {
  padding-top: 1em;
}

.sp-content-image {
  margin-right: 5em;
}

.sp-content-image img {
  width: 450px;
}

.sp-service {
  margin-left: 8em;
  margin-top: 0.5em;
  width: 80%;
}

.sp-service-title {
  font-size: 0.9rem;
  font-family: Poppins;
  font-weight: 500;
  color: var(--sp_service_title);
}

.sp-service-content {
  font-size: 0.75rem;
  font-family: Poppins;
  font-weight: 300;
  letter-spacing: 1px;
  color: var(--sp_service_content);
  margin-left: 1em;
  padding-top: 3px;
  padding-bottom: 10px;
  width: 80%;
}

.section-3 {
  width: 100%;
  height: 100vh;
}

.section-3 > img {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  object-fit: cover;
}

.audit-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.audit-content img {
  width: 450px;
}

.audit-content-details {
  font-family: Poppins;
  width: 50%;
}

.audit-title {
  color: white;
  font-size: 2.8rem;
  margin-bottom: 0.5rem;
}

.audit-subtitle {
  width: 90%;
  color: var(--sub_title_color);
  font-size: 0.8rem;
  margin-bottom: 2rem;
}

.audit-button {
  color: white;
  font-size: 0.75rem;
  font-weight: lighter;
  letter-spacing: 1px;
  border: 1px solid var(--audit_button_border);
  padding: 10px 16px;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
}

.audit-button:hover {
  background-color: var(--audit_button_border);
}

.sc-title {
  margin-top: 2em;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  background: var(--linear_title);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cs-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  margin-top: 10em;
}

.zero-one-background {
  position: absolute;
  width: 200px;
  right: 0;
  margin-top: -150px;
  z-index: -1;
}

.shield-background {
  position: absolute;
  z-index: -1;
  margin-top: 70em;
}

.sc-card-container {
  position: relative;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6em;
}

.articles-title {
  margin-top: 6em;
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
  background: var(--articles-card);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-container-button {
  display: flex;
  align-items: center;
  justify-content: end;
}

.contact-form {
  margin-left: auto;
  margin-right: auto;
}

.contact-title {
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
}

.contact-image {
  /* height: 100vh; */
  object-fit: cover;
}

/* ? About us Page */
.about-container {
  font-family: Poppins;
  width: 100%;
  margin-top: 6em;
}

.about-background {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.about-title {
  color: white;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
}

.about-title span {
  color: rgb(242, 242, 242);
  font-size: 2.5rem;
  font-weight: 400;
}

.about-sub-title {
  color: white;
  font-size: 0.8rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 3em;
  letter-spacing: 2px;
  word-spacing: 6px;
  text-shadow: 5px;
}

.about-content {
  color: white;
  margin-left: 15%;
}

.about-content div {
  padding-top: 1em;
}

.emp-title {
  margin-top: 3em;
  text-align: center;
  color: white;
  font-size: 2rem;
  font-family: Poppins;
}

.emp-cards {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.emp-card {
  margin-top: -30em;
  position: relative;
  width: 23%;
  transition: 0.3s ease-in-out;
}

.emp-card-info {
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.emp-card-info-name {
  color: white;
  font-size: 1.3rem;
  margin-bottom: 1em;
}

.emp-card-info-title {
  font-size: 1rem;
  color: var(--footer_background);
}

.emp-image {
  position: absolute;
  width: 100%;
  height: 400px;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
}

.emp-image:hover {
  transform: translateY(5.5em);
}

/* ? Form */
.contact-us-button {
  background-color: #2b9630;
  color: white;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  border: none;
  border-radius: 2px;
  padding: 6px 26px;
  box-shadow: 0px 1px 3px rgb(50, 50, 50);
  transition: 0.3s ease-in-out;
}

.contact-us-button:hover {
  background-color: #2e7d32;
}





